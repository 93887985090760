<template>
    <div class="option-manage" ref="optionmanage">
        <div class="title">
            <Button type="primary" @click="addOption">添加选项</Button>
            <div class="select">
                <span>页面排序方式：</span>
                <Select v-model="formValidate.voteItemSeq" style="width: 200px">
                    <Option v-for="(item, index) in formValidate.voteItemSeqList" :value="item.dictKey" :key="index">{{ item.dictValue }}</Option>
                </Select>
            </div>
            <span class="desc">纯文字投票只需要填写文字，详情描述选填</span>
        </div>
        <div class="content">
            <Table :columns="columns" :data="formValidate.voteItemRos">
                <template slot-scope="row" slot="action">
                    <Upload
                        :before-upload="beforeImage"
                        :show-upload-list="false"
                        :action="actionImage"
                        :on-format-error="UploadImageError"
                        :data="uploadImageData"
                        :on-success="(value, file) => imageSuccess(file, row.index)"
                        :format="['jpg', 'jpeg', 'png']"
                        :max-size="2038"
                        :on-exceeded-size="imageExceededSize"
                    >
                        <Button type="info">上传图片</Button>
                    </Upload>
                    <Upload
                        ref="upload"
                        :before-upload="beforeVideo"
                        :action="actionVideo"
                        :show-upload-list="false"
                        :on-format-error="UploadImageError"
                        :data="uploadVideoData"
                        :on-success="(value, file) => videoSuccess(file, row.index)"
                        :format="['mp4', 'flv', 'wmv', 'avi', 'mov', 'f4v', 'mkv']"
                        :max-size="102400"
                        :on-exceeded-size="videoExceededSize"
                    >
                        <Button type="primary">上传视频</Button>
                    </Upload>
                    <Button type="error" @click="deleteOption(row)">删除选项</Button>
                </template>
            </Table>
        </div>
        <LiefengModal title="查看投票" :value="seeModalStatus" :fullscreen="false" @input="seeModalStatusFn">
            <template v-slot:contentarea>
                <Input v-if="isType == 'image'" v-model.trim="imageUrl" placeholder="请输入链接地址"></Input>
                <Input v-if="isType == 'video'" v-model.trim="videoUrl" placeholder="请输入链接地址"></Input>
            </template>
            <template v-slot:toolsbar>
                <Button type="primary" @click="seeModalStatusFn(false)">取消</Button>
                <Button type="primary" @click="saveUrl" style="margin-left: 10px">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
export default {
    components: { LiefengModal },
    props: ["formValidate", "ruleValidate"],
    data() {
        const validateTitle = (rule, value, callback) => {
            let six = rule.field.substring(rule.field.indexOf("[") + 1, rule.field.indexOf("]"))
            if (!this.formValidate.voteItemRos[six].title) {
                return callback(new Error("请输入标题"))
            } else if (this.formValidate.voteItemRos[six].title.length > 20) {
                return callback(new Error("标题不能超过20字"))
            } else {
                callback()
            }
        }
        return {
            tableData: [
                {
                    number: "",
                    title: "",
                    detail: "",
                    imgSrc: "",
                },
            ],
            columns: [
                {
                    title: "序号",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("InputNumber", {
                                props: {
                                    value: params.row.seq,
                                    min: 1,
                                },
                                on: {
                                    "on-change": e => {
                                        this.changeTable(e, params.index, "seq")
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "文本内容",
                    width: 500,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Form",
                            {
                                prop: {
                                    model: this.formValidate.voteItemRos,
                                },
                            },
                            [
                                h(
                                    "FormItem",
                                    {
                                        style: {
                                            marginBottom: "26px",
                                        },
                                        props: {
                                            prop: "voteItemRos[" + params.index + "].title",
                                            rules: { validator: validateTitle, trigger: "blur" },
                                        },
                                    },
                                    [
                                        h("Input", {
                                            props: {
                                                placeholder: "请输入选项标题。限20字，必填",
                                                value: params.row.title,
                                            },
                                            on: {
                                                "on-change": e => {
                                                    this.changeTable(e.target.value, params.index, "title")
                                                },
                                            },
                                        }),
                                    ]
                                ),
                                h("Input", {
                                    key: "subtitle",
                                    props: {
                                        placeholder: "详情描述，限200字，非必填",
                                        value: params.row.subtitle,
                                    },
                                    on: {
                                        "on-change": e => {
                                            this.changeTable(e.target.value, params.index, "subtitle")
                                        },
                                    },
                                }),
                            ]
                        )
                    },
                },
                {
                    title: "封面预览（选填）",
                    // slot: "cover",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("img", {
                                attrs: {
                                    width: 100,
                                    height: 100,
                                    src: this.formValidate.voteItemRos[params.index].coverImg,
                                },
                                style: {
                                    width: "100px",
                                    height: "100px",
                                    border: "1px solid #ccc",
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "",
                    width: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    attrs: {
                                        type: "info",
                                    },
                                    on: {
                                        click: () => {
                                            this.rowIndex = params.index
                                            this.isType = "image"
                                            this.seeModalStatusFn(true)
                                        },
                                    },
                                    style: {
                                        margin: "10px auto",
                                        textAlign: "center",
                                        display: "block",
                                    },
                                },
                                "图片地址" //1001
                            ),
                            h(
                                "Button",
                                {
                                    attrs: {
                                        type: "info",
                                    },
                                    on: {
                                        click: () => {
                                            this.rowIndex = params.index
                                            this.isType = "video"
                                            this.seeModalStatusFn(true)
                                        },
                                    },
                                    style: {
                                        margin: "10px auto",
                                        textAlign: "center",
                                        display: "block",
                                    },
                                },
                                "视频地址" //1001
                            ),
                        ])
                    },
                },
                {
                    title: "操作",
                    slot: "action",
                    width: 160,
                    align: "center",
                },
            ],
            // 图片、视频上传参数
            uploadImageData: {},
            uploadVideoData: {},
            //图片、视频名称
            imageName: "",
            videoName: "",
            //图片、视频路径
            imagePath: "",
            videoPath: "",
            //oss上传地址
            actionImage: "",
            actionVideo: "",

            seeModalStatus: false,

            isType: "",

            imageUrl: "",
            videoUrl: "",
            rowIndex: null,
        }
    },
    methods: {
        saveUrl() {
            console.log('确定按钮',this.isType);
            if (this.isType == "image") this.formValidate.voteItemRos[this.rowIndex].coverImg = this.imageUrl
            else if (this.isType == "video") {
                console.log('如果是视频类型进这里');
                try {
                    this.formValidate.voteItemRos[this.rowIndex].converVideo = this.videoUrl
                    this.getVideoImage(
                        this.videoUrl,
                        async img => {
                            await this.beforeImage(this.$core.dataURLtoFile(img, Date.parse(new Date()) / 1000, "image/jpg"))
                            const boundary = `----WebKitFormBoundary${new Date().getTime()}`
                            let fromData = new FormData()
                            fromData.append("name", this.uploadImageData.name + ".jpg")
                            fromData.append("host", this.uploadImageData.host)
                            fromData.append("key", this.uploadImageData.key)
                            fromData.append("policy", this.uploadImageData.policy)
                            fromData.append("OSSAccessKeyId", this.uploadImageData.OSSAccessKeyId)
                            fromData.append("success_action_status", "200")
                            fromData.append("signature", this.uploadImageData.signature)
                            fromData.append("file", this.$core.dataURLtoFile(img, Date.parse(new Date()) / 1000, "image/jpg"))
                            await this.$post(window.vue.getProxy()["/oss"].target, fromData, { "content-type": `multipart/form-data; boundary=${boundary}` })
                            console.log('视频封面处理完毕');
                            this.$Message.destroy()
                            this.$Message.success({
                                background: true,
                                content: "视频封面处理完毕",
                            })
                            this.$emit("changeData", {
                                value: this.imagePath,
                                index: this.rowIndex,
                                name: "coverImg",
                            })
                        },
                        3
                    )
                } catch (err) {
                    console.log('整个方法抛异常',err);
                }

                this.$emit("changeData", {
                    value: this.videoUrl,
                    index: this.rowIndex,
                    name: "converVideo",
                })
                this.$Message.destroy()
            }
            this.seeModalStatusFn(false)
        },
        //查看弹窗
        seeModalStatusFn(status) {
            this.seeModalStatus = status
            if (!status) {
                if (this.isType == "image") this.imageUrl = ""
                else if (this.isType == "video") this.videoUrl = ""
            }
        },
        getVideoImage(path, callback, secs = 1) {
            console.log('调用获取视频封面方法',path);
            this.$Message.loading({
                background: true,
                content: "正在处理视频封面，请稍等",
            })
            try {
                console.log('处理video标签');
                var me = this,
                    video = document.createElement("video")
                video.src = path
                video.setAttribute("crossOrigin", "Anonymous") // 处理跨域
                video.setAttribute("preload", "auto") // auto|metadata|none
                video.onloadedmetadata = function () {
                    if ("function" === typeof secs) {
                        secs = secs(this.duration)
                    }
                    this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration)
                }
                console.log('显示处理',video);
                video.onseeked = e => {
                    var canvas = document.createElement("canvas")
                    canvas.height = video.videoHeight
                    canvas.width = video.videoWidth
                    var ctx = canvas.getContext("2d")
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                    var img = new Image()
                    img.src = canvas.toDataURL()
                    callback.call(me, img.src, this.currentTime, e)
                }
                video.onerror = e => {
                    console.log('如果视频显示报错',e);
                    callback.call(me, undefined, undefined, e)
                }
                video.src = path
            } catch (err) {
                console.log("err", err)
            }
        },
        //删除选项
        deleteOption(row) {
            this.$emit("delOption", row.index)
        },
        //添加选项
        addOption() {
            this.$emit("addOption")
        },
        //视频上传成功
        async videoSuccess(file, index) {
            if (file.status == "finished") {
                this.getVideoImage(
                    this.videoPath,
                    async img => {
                        console.log('处理完毕封面之后进这里',img);
                        await this.beforeImage(this.$core.dataURLtoFile(img, Date.parse(new Date()) / 1000, "image/jpg"))
                        const boundary = `----WebKitFormBoundary${new Date().getTime()}`
                        let fromData = new FormData()
                        fromData.append("name", this.uploadImageData.name + ".jpg")
                        fromData.append("host", this.uploadImageData.host)
                        fromData.append("key", this.uploadImageData.key)
                        fromData.append("policy", this.uploadImageData.policy)
                        fromData.append("OSSAccessKeyId", this.uploadImageData.OSSAccessKeyId)
                        fromData.append("success_action_status", "200")
                        fromData.append("signature", this.uploadImageData.signature)
                        fromData.append("file", this.$core.dataURLtoFile(img, Date.parse(new Date()) / 1000, "image/jpg"))
                        await this.$post(window.vue.getProxy()["/oss"].target, fromData, { "content-type": `multipart/form-data; boundary=${boundary}` })
                        this.$Message.destroy()
                        this.$Message.success({
                            background: true,
                            content: "视频封面处理完毕",
                        })
                        this.$emit("changeData", {
                            value: this.imagePath,
                            index: index,
                            name: "coverImg",
                        })
                    },
                    3
                )
                this.$emit("changeData", {
                    value: this.videoPath,
                    index: index,
                    name: "converVideo",
                })
                this.$Message.destroy()
                this.$Message["success"]({
                    background: true,
                    content: "视频上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "视频上传失败！",
                })
            }
        },
        //视频上传之前
        beforeVideo(file) {
            return this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = Date.parse(new Date()) / 1000
                    //防止文件名有.
                    let suffixArr = file.name.split(".")

                    let suffix = "." + suffixArr[suffixArr.length - 1]
                    this.$Message.loading({
                        background: true,
                        content: "正在上传视频",
                    })
                    this.actionVideo = window.vue.getProxy()["/oss"].target
                    this.uploadVideoData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.videoName = now + suffix
                    this.videoPath = res.data.host + res.data.key + this.videoName
                }
            })
        },
        //图片上传成功
        imageSuccess(file, index) {
            if (file.status == "finished") {
                this.$emit("changeData", {
                    value: this.imagePath,
                    index: index,
                    name: "coverImg",
                })
                this.$emit("changeData", {
                    value: "",
                    index: index,
                    name: "converVideo",
                })
                this.$Message["success"]({
                    background: true,
                    content: "图片上传成功！",
                })
            } else {
                this.$Message["error"]({
                    background: true,
                    content: "图片上传失败！",
                })
            }
        },
        //视频大小超出限制
        videoExceededSize(file) {
            this.$Notice.error({
                title: "上传失败",
                duration: 0,
                desc: "文件" + file.name + "过大，请上传100M以内的视频",
            })
        },
        //图片大小超出限制
        imageExceededSize(file) {
            this.$Notice.error({
                title: "上传失败",
                duration: 0,
                desc: "文件" + file.name + "过大，请上传2M以内的图片",
            })
        },
        //视频格式错误
        UploadVideoError(file) {
            this.$Notice.error({
                title: "上传失败",
                duration: 0,
                desc: "文件" + file.name + "格式错误，请上传'mp4','flv','wmv','avi','mov','f4v','mkv'格式的视频",
            })
        },
        //图片格式错误
        UploadImageError(file) {
            this.$Notice.error({
                title: "上传失败",
                duration: 0,
                desc: "文件" + file.name + "格式错误，请上传'jpg', 'jpeg', 'png'格式的图片",
            })
        },
        //上传图片之前
        async beforeImage(file, type) {
            console.log('上传图片');
            return await this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng").then(res => {
                if (res.code == 200) {
                    let now = Date.parse(new Date()) / 1000
                    //防止文件名有.
                    let suffixArr = file.name.split(".")
                    let suffix = ""
                    if (type && type != "") {
                        suffix = "." + suffixArr[suffixArr.length - 1]
                        this.videoPath = ""
                    } else suffix = suffixArr[suffixArr.length - 1]
                    this.actionImage = window.vue.getProxy()["/oss"].target
                    this.uploadImageData = {
                        name: now + suffix,
                        host: res.data.host,
                        key: res.data.key + now + suffix,
                        policy: res.data.encodedPolicy,
                        OSSAccessKeyId: res.data.accessId,
                        success_action_status: "200",
                        signature: res.data.postSignature,
                    }
                    this.imageName = now + suffix
                    this.imagePath = res.data.host + res.data.key + this.imageName
                }
            })
        },

        //修改table项数据
        changeTable(value, index, name) {
            this.$emit("changeData", { value, index, name })
        },
        backStep() {
            this.$emit("nextStep", -1)
        },
        nextStep() {
            this.$emit("nextStep", 1)
        },
    },
}
</script>
    
<style scoped lang='less'>
.option-manage {
    width: 100%;
    .title {
        display: flex;
        align-items: center;
        span {
            margin-left: 30px;
        }
        .desc {
            color: #ccc;
        }
    }
    .content {
        margin-top: 20px;
        /deep/.ivu-table {
            th {
                background-color: transparent;
            }
        }
        /deep/table {
            width: 100% !important;
        }
    }

    .ivu-table-cell-slot {
        .ivu-btn {
            margin: 10px 0;
        }
    }
    .bottom {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        button {
            margin-left: 20px;
        }
    }
}
</style>